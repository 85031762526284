<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl class="box">
            <!-- vhod, izhod -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                        <v-card-title>
                            <v-icon left>mdi-turnstile</v-icon>
                                    <span class="subtitle-1 font-weight-regular application--card--title">{{ pg_device_id_1_name }} </span>
                                    <v-spacer></v-spacer>
                                    <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                        <!--<template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <span class="beacon_green" v-if="device_1_online">&nbsp;</span>
                                                <span class="beacon_red" v-else>&nbsp;</span>
                                            </div>
                                        </template>
                                        <span>{{ (device_1_online) ? "Online" : "Offline" }}</span>-->
                                    </v-tooltip>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="deviceUpdateTimestampColor(device_1_update_timestamp_dt)">mdi-information-outline</v-icon>
                                        </template>
                                        <span>Čas posodobitve: {{ device_1_update_timestamp }}</span>
                                    </v-tooltip>
                                    &nbsp;&nbsp;
                                    <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="socTempColor('rpi1')">mdi-thermometer</v-icon>
                                        </template>
                                        <span>Temperatura (SoC): {{ device_1_soc_temp }} {{(device_1_soc_temp !== null && device_1_soc_temp !== "") ? "°C" : "N/A" }}</span>
                                    </v-tooltip>
                                <v-btn icon @click="card1 = !card1">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <div>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                            <v-icon size="40" :color="(device_1_status == 1) ? 'success' : 'error'">mdi-turnstile</v-icon>
                                        </v-list-item-icon>
                                
                                        <v-list-item-content >
                                            <v-list-item-title class="body-2 mt-2" >Pozicija križnih zapor</v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-chip
                                            class=""
                                            :color="(device_1_status == 1) ? 'success' : 'error'"
                                            text-color="white"
                                            >
                                                <v-avatar left>
                                                    <v-icon>{{ (device_1_status == 1) ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
                                                </v-avatar>
                                                {{ (device_1_status == 1) ? 'Odprta' : 'Zaprta' }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <!--<v-list-item>
                                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                            <v-icon size="40" :color="(device_1_online) ? 'success' : 'error'">mdi-raspberry-pi</v-icon>
                                        </v-list-item-icon>
                                
                                        <v-list-item-content >
                                            <v-list-item-title class="body-2 mt-2" >Terminal</v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-chip
                                            class=""
                                            :color="(device_1_online) ? 'success' : 'error'"
                                            text-color="white"
                                            >
                                                <v-avatar left>
                                                    <v-icon>{{ (device_1_online == 1) ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
                                                </v-avatar>
                                                {{ (device_1_online) ? status_online_text : status_offline_text }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>-->
                                </v-list>
                            </div>
                        </v-card-text>
                        <v-divider v-show="card1"></v-divider>
                        <v-app-bar
                            v-show="card1"
                            flat
                            color="white"
                            dense
                            class="elevation-0"
                        >   
                            <v-icon left>mdi-walk</v-icon>
                            <v-toolbar-title class="body-2">Prehod (odpri vrtljiva vrata)</v-toolbar-title>
                        </v-app-bar>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <!--<v-btn @click="send({device_id: pg_device_id_1, event_type_id: 9, key: pg_open_key_1})" :disabled="loading1 || disabled  || !device_1_online" :loading="loading1" large class="dvig" block>Enkratni prehod</v-btn>-->
                                        <v-btn @click="send({device_id: pg_device_id_1, event_type_id: 9, key: pg_open_key_1})" :disabled="loading1 || disabled" :loading="loading1" large class="dvig" block>Enkratni prehod</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <!--<v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <v-btn @click="send({device_id: pg_device_id_1, event_type_id: 10, key: pg_lock_key_1})" :disabled="loading7 || disabled  || !device_1_online" :loading="loading7" large class="spust" block>Zapri</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>-->
                            <v-container grid-list-xs>
                                <v-layout row wrap align-center justify-center>
                                    <v-flex xs12 md12 lg12>
                                        <!--<v-btn @click="send({device_id: pg_device_id_1, event_type_id: 12, key: rpi_1})" :disabled="loading4 || disabled  || !device_1_online" :loading="loading4" large class="stalni_dvig" block>Reset</v-btn>-->
                                        <v-btn @click="send({device_id: pg_device_id_1, event_type_id: 12, key: rpi_1})" :disabled="loading4 || disabled" :loading="loading4" large class="stalni_dvig" block>Reset</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>

                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-turnstile</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">{{ pg_device_id_2_name }}</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                            <!--<template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                    <span class="beacon_green" v-if="device_2_online">&nbsp;</span>
                                                    <span class="beacon_red" v-else>&nbsp;</span>
                                                </div>
                                            </template>
                                            <span>{{ (device_2_online) ? "Online" : "Offline" }}</span>-->
                                        </v-tooltip>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="deviceUpdateTimestampColor(device_2_update_timestamp_dt)">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas posodobitve: {{ device_2_update_timestamp }}</span>
                                        </v-tooltip>
                                        &nbsp;&nbsp;
                                    <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="socTempColor('rpi2')">mdi-thermometer</v-icon>
                                        </template>
                                        <span>Temperatura (SoC): {{ device_2_soc_temp }} {{(device_2_soc_temp !== null && device_2_soc_temp !== "") ? "°C" : "N/A" }}</span>
                                    </v-tooltip>
                                    <v-btn icon @click="card2 = !card2">
                                        <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">
                                <div>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="(device_2_status == 1) ? 'success' : 'error'">mdi-turnstile</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija križnih zapor</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="(device_2_status == 1) ? 'success' : 'error'"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ (device_2_status == 1) ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
                                                    </v-avatar>
                                                    {{ (device_2_status == 1) ? 'Odprta' : 'Zaprta' }}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!--
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="(device_2_online) ? 'success' : 'error'">mdi-raspberry-pi</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Terminal</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="(device_2_online) ? 'success' : 'error'"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ (device_2_online == 1) ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
                                                    </v-avatar>
                                                    {{ (device_2_online) ? status_online_text : status_offline_text }}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    -->
                                    </v-list>
                                </div>
                            </v-card-text>
                            <v-divider v-show="card2"></v-divider>
                            <v-app-bar
                                v-show="card2"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-walk</v-icon>
                                <v-toolbar-title class="body-2">Prehod (odpri vrtljiva vrata)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <!--<v-btn @click="send({device_id: pg_device_id_2, event_type_id: 9, key: pg_open_key_2})" :disabled="loading2 || disabled  || !device_2_online" :loading="loading2" large class="dvig" block>Enkratni prehod</v-btn>-->
                                            <v-btn @click="send({device_id: pg_device_id_2, event_type_id: 9, key: pg_open_key_2})" :disabled="loading2 || disabled" :loading="loading2" large class="dvig" block>Enkratni prehod</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <!--<v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_2, event_type_id: 10, key: pg_lock_key_2})" :disabled="loading8 || disabled  || !device_2_online" :loading="loading8" large class="spust" block>Zapri</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <!--<v-btn @click="send({device_id: pg_device_id_2, event_type_id: 12, key: rpi_2})" :disabled="loading5 || disabled  || !device_2_online" :loading="loading5" large class="stalni_dvig" block>Reset</v-btn>-->
                                            <v-btn @click="send({device_id: pg_device_id_2, event_type_id: 12, key: rpi_2})" :disabled="loading5 || disabled" :loading="loading5" large class="stalni_dvig" block>Reset</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>

            <!-- izhod -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-turnstile</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">{{ pg_device_id_3_name }}</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                            <!--<template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                    <span class="beacon_green" v-if="device_3_online">&nbsp;</span>
                                                    <span class="beacon_red" v-else>&nbsp;</span>
                                                </div>
                                            </template>
                                            <span>{{ (device_3_online) ? "Online" : "Offline" }}</span>-->
                                        </v-tooltip>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="deviceUpdateTimestampColor(device_3_update_timestamp_dt)">mdi-information-outline</v-icon>
                                            </template>
                                            <span>Čas posodobitve: {{ device_3_update_timestamp }}</span>
                                        </v-tooltip>
                                        &nbsp;&nbsp;
                                    <v-tooltip :left="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.smAndDown">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon style="margin-right:5px;" v-bind="attrs" v-on="on" :color="socTempColor('rpi3')">mdi-thermometer</v-icon>
                                        </template>
                                        <span>Temperatura (SoC): {{ device_3_soc_temp }} {{(device_3_soc_temp !== null && device_3_soc_temp !== "") ? "°C" : "N/A" }}</span>
                                    </v-tooltip>
                                    <v-btn icon @click="card3 = !card3">
                                        <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <div>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="(device_3_status == 1) ? 'success' : 'error'">mdi-turnstile</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Pozicija križnih zapor</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="(device_3_status == 1) ? 'success' : 'error'"
                                                text-color="white"
                                                >
                                                <v-avatar left>
                                                    <v-icon>{{ (device_3_status == 1) ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
                                                </v-avatar>
                                                {{ (device_3_status == 1) ? 'Odprta' : 'Zaprta' }}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!--<v-list-item>
                                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                                <v-icon size="40" :color="(device_2_online) ? 'success' : 'error'">mdi-raspberry-pi</v-icon>
                                            </v-list-item-icon>
                                    
                                            <v-list-item-content >
                                                <v-list-item-title class="body-2 mt-2" >Terminal</v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-chip
                                                class=""
                                                :color="(device_3_online) ? 'success' : 'error'"
                                                text-color="white"
                                                >
                                                    <v-avatar left>
                                                        <v-icon>{{ (device_3_online == 1) ? 'mdi-check' : 'mdi-cancel' }}</v-icon>
                                                    </v-avatar>
                                                    {{ (device_3_online) ? status_online_text : status_offline_text }}
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>-->
                                    </v-list>
                                </div>
                            </v-card-text>
                            <v-divider v-show="card3"></v-divider>
                            <v-app-bar
                                v-show="card3"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-walk</v-icon>
                                <v-toolbar-title class="body-2">Prehod (odpri vrtljiva vrata)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card3"></v-divider>
                            <v-card-text v-show="card3">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <!--<v-btn @click="send({device_id: pg_device_id_3, event_type_id: 9, key: pg_open_key_3})" :disabled="loading3 || disabled  || !device_3_online" :loading="loading3" large class="dvig" block>Enkratni prehod</v-btn>-->
                                            <v-btn @click="send({device_id: pg_device_id_3, event_type_id: 9, key: pg_open_key_3})" :disabled="loading3 || disabled" :loading="loading3" large class="dvig" block>Enkratni prehod</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <!--<v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <v-btn @click="send({device_id: pg_device_id_3, event_type_id: 10, key: pg_lock_key_3})" :disabled="loading9 || disabled  || !device_3_online" :loading="loading9" large class="spust" block>Zapri</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>
                                            <!--<v-btn @click="send({device_id: pg_device_id_3, event_type_id: 12, key: rpi_3})" :disabled="loading6 || disabled  || !device_3_online" :loading="loading6" large class="stalni_dvig" block>Reset</v-btn>-->
                                            <v-btn @click="send({device_id: pg_device_id_3, event_type_id: 12, key: rpi_3})" :disabled="loading6 || disabled" :loading="loading6" large class="stalni_dvig" block>Reset</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>

                <!-- IZHOD -->
                <!--<v-flex xs12 sm12 md5 lg5 xl4>
                    <v-card>
                            <v-card-title>
                                <v-icon left>mdi-turnstile</v-icon>
                                        <span class="subtitle-1 font-weight-regular application--card--title">{{ pg_device_id_4_name }}</span>
                                        <v-spacer></v-spacer>
                                    <v-btn icon @click="card4 = !card4">
                                        <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card4"></v-divider>
                            <v-app-bar
                                v-show="card4"
                                flat
                                color="white"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-walk</v-icon>
                                <v-toolbar-title class="body-2">Prehod (odpri vrtljiva vrata)</v-toolbar-title>
                            </v-app-bar>
                            <v-divider v-show="card4"></v-divider>
                            <v-card-text v-show="card4">
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>-->
                                            <!--<v-btn @click="send({device_id: pg_device_id_4, event_type_id: 9, key: pg_open_key_4})" :disabled="loading7 || disabled" :loading="loading7" large class="dvig" block>Enkratni prehod</v-btn>-->
                                            <!--<v-btn @click="send({device_id: pg_device_id_4, event_type_id: 9, key: pg_open_key_4})" disabled :loading="loading7" large class="dvig" block>Enkratni prehod</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <v-container grid-list-xs>
                                    <v-layout row wrap align-center justify-center>
                                        <v-flex xs12 md12 lg12>-->
                                            <!--<v-btn @click="send({device_id: pg_device_id_4, event_type_id: 12, key: rpi_4})" :disabled="loading8 || disabled" :loading="loading8" large class="stalni_dvig" block>Reset</v-btn>-->
                                            <!--<v-btn @click="send({device_id: pg_device_id_4, event_type_id: 12, key: rpi_4})" disabled large class="stalni_dvig" block>Reset</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>-->
                <v-flex xs12 sm12 md5 lg5 xl4>&nbsp;</v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
import * as mqtt from "mqtt" 
import moment from 'moment'

export default {


    data: () => ({
        
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        card5: true,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        loading6: false,
        loading7: false,
        loading8: false,
        loading9: false,
        disabled: false,

        interval: null,
        interval2: null,
        timeout1: null,
        timeout2:null,
        devices: [],

        status_offline_text: "Offline",
        status_online_text: "Online",

        pg_device_id_1_name: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_1_NAME,
        pg_device_id_1: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_1,
        pg_open_key_1: process.env.VUE_APP_DATA_TURNSTILE_OPEN_KEY_1,
        pg_lock_key_1: process.env.VUE_APP_DATA_TURNSTILE_LOCK_KEY_1,
        rpi_1: process.env.VUE_APP_DATA_RPI_1,

        rpi_1_status_name: null,
        rpi_1_status_update_timestamp: "05.03.2023 08:00:15",

        pg_device_id_2_name: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_2_NAME,
        pg_device_id_2: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_2,
        pg_open_key_2: process.env.VUE_APP_DATA_TURNSTILE_OPEN_KEY_2,
        pg_lock_key_2: process.env.VUE_APP_DATA_TURNSTILE_LOCK_KEY_2,
        rpi_2: process.env.VUE_APP_DATA_RPI_2,
        
        rpi_2_status_name: null,
        rpi_2_status_update_timestamp: "05.03.2023 08:00:15",

        pg_device_id_3_name: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_3_NAME,
        pg_device_id_3: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_3,
        pg_open_key_3: process.env.VUE_APP_DATA_TURNSTILE_OPEN_KEY_3,
        pg_lock_key_3: process.env.VUE_APP_DATA_TURNSTILE_LOCK_KEY_3,
        rpi_3: process.env.VUE_APP_DATA_RPI_3,
        
        rpi_3_status_name: null,
        rpi_3_status_update_timestamp: "05.03.2023 08:00:15",

        pg_device_id_4_name: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_4_NAME,
        pg_device_id_4: process.env.VUE_APP_DATA_TURNSTILE_DEVICE_ID_4,
        pg_open_key_4: process.env.VUE_APP_DATA_TURNSTILE_OPEN_KEY_4,
        pg_lock_key_4: process.env.VUE_APP_DATA_TURNSTILE_LOCK_KEY_4,
        rpi_4: process.env.VUE_APP_DATA_RPI_4,
        rpi_4_status: 0,
        rpi_4_status_name: null,
        rpi_14status_update_timestamp: "05.03.2023 08:00:15",

        device_1: process.env.VUE_APP_DEVICE_1_UUID,
        device_2: process.env.VUE_APP_DEVICE_2_UUID,
        device_3: process.env.VUE_APP_DEVICE_3_UUID,

        device_1_update_timestamp: null,
        device_1_update_timestamp_dt: null,
        device_2_update_timestamp: null,
        device_2_update_timestamp_dt: null,
        device_3_update_timestamp: null,
        device_3_update_timestamp_dt: null,

        device_1_soc_temp: null,
        device_2_soc_temp: null,
        device_3_soc_temp: null,

        device_1_online: false,
        device_1_identification_timestamp: null,
        device_1_status: null,
        device_2_online: false,
        device_2_identification_timestamp: null,
        device_2_status: null,
        device_3_online: false,
        device_3_identification_timestamp: null,
        device_3_status: null,
    }),

    watch: {
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

    },

    methods: {

        deviceUpdateTimestampColor(deviceUpdateTimestamp) {
            window.console.log("Device last update timestamp: ", deviceUpdateTimestamp);
            window.console.log("Current timestamp: ", moment().format('YYYY-MM-DD HH:mm:ss'))
            if(deviceUpdateTimestamp !== null) {
                let currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss')

                let calculation = (new Date(currentTimestamp) - new Date(deviceUpdateTimestamp)) / 1_000;

                ///window.console.log("calculation: ", calculation)

                if(calculation > 45) {
                    return "error"
                }

                return "success"
            }

            return "error";
        },

        socTempColor(device) {
            let color = "success"

            if(device == "rpi1") {
                if(this.device_1_soc_temp > 57 && this.device_1_soc_temp < 67) {
                    color = 'warning'
                }

                if(this.device_1_soc_temp >= 67) {
                    color = 'error'
                }
            }

            if(device == "rpi2") {
                if(this.device_2_soc_temp > 57 && this.device_2_soc_temp < 67) {
                    color = 'warning'
                }

                if(this.device_2_soc_temp >= 67) {
                    color = 'error'
                }
            }

            if(device == "rpi3") {
                if(this.device_3_soc_temp > 57 && this.device_3_soc_temp < 67) {
                    color = 'warning'
                }

                if(this.device__soc_temp >= 67) {
                    color = 'error'
                }
            }

            return color;
        },

        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        send(data) {

            window.console.log(data)
            var vm = this
            let payload = {
                topic:"turnstiles",
                application_uuid: process.env.VUE_APP_UUID,
                payload: data
            };

            if(data.event_type_id == 12) {
                payload.topic = "restart-rpi";
            }

            let snackbarText = ''

            if(data.key == 'krizna_zapora_105_odpri') {
                this.loading1 = true
                snackbarText = "Odpiranje križne zapore na glavnem vhodu je uspelo."
            }

            if(data.key == 'krizna_zapora_105_zapri') {
                this.loading1 = true
                snackbarText = "Zapiranje križne zapore na glavnem vhodu je uspelo."
            }

            if(data.key == 'krizna_zapora_106_odpri') {
                this.loading3 = true
                snackbarText = "Odpiranje križne zapore pri vhodu Žabče je uspelo."
            }

            if(data.key == 'krizna_zapora_106_zapri') {
                this.loading3 = true
                snackbarText = "Zapiranje križne zapore pri vhodu Žabče je uspelo."
            }

            if(data.key == 'krizna_zapora_107_odpri') {
                this.loading3 = true
                snackbarText = "Odpiranje križne zapore na Dantejevem vhodu je uspelo."
            }

            if(data.key == 'krizna_zapora_107_zapri') {
                this.loading3 = true
                snackbarText = "Zapiranje križne zapore na Dantejevem vhodu je uspelo."
            }

            if(data.key == 'krizna_zapora_108_odpri') {
                this.loading3 = true
                snackbarText = "Odpiranje izhodne križne zapore je uspelo."
            }

            if(data.key == 'terminal_86_reset') {
                this.loading4 = true;
                snackbarText = "Resetiranje križne zapore na glavnem vhodu je uspelo."
            }

            if(data.key == 'terminal_87_reset') {
                this.loading4 = true;
                snackbarText = "Resetiranje križne zapore pri vhodu Žabče je uspelo."
            }

            if(data.key == 'terminal_88_reset') {
                this.loading4 = true;
                snackbarText = "Resetiranje križne zapore na Dantejevem vhodu je uspelo."
            }

            if(data.key == 'terminal_89_reset') {
                this.loading4 = true;
                snackbarText = "Resetiranje izhodne križne zapore je uspelo."
            }


            this.disabled = true;
            vm.$store.dispatch('PUBLISH', payload)
            .then(response => {
                //window.console.log(response)
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', snackbarText)
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .catch(error => {
                vm.$store.commit('snackbarDisplay', false)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', "Pri upravljanju križne zapore je prišlo do napake. Prosimo poskusite ponovno.")
                vm.$store.commit('snackbarTimeout', 7000)
                vm.$store.commit('snackbarDisplay', true)
            })
            .finally(() => {
                this.loading1 = false
                this.loading2 = false
                this.loading3 = false
                this.loading4 = false
                this.loading5 = false
                this.loading6 = false
                this.loading7 = false
                this.loading8 = false
                this.loading9 = false
                setTimeout(() => {
                    vm.disabled = false;
                }, 4000)
            })

        },

        deviceStatusColor(value) {
            if(value == 1) {
                return 'success'
            }

            return 'error'
        },

        deviceStatus(value) {
            if(value == 1) {
                return 'Odprta'
            }

            return 'Zaprta'
        },

        rpiStatusColor(value) {
            if(value == 1) {
                return 'success'
            }

            return 'error'
        },

        setupDeviceData() {
            let deviceData = this.$store.getters.rpiDevices;

            //window.console.log(deviceData)

            deviceData.forEach(data => {
                if("id" in data) {
                    //window.console.log("a")
                    if(data.id === this.device_1) {
                        if("online" in data) {
                            if(data.online) {
                                this.device_1_online = true
                            } else {
                                this.device_1_online = false
                            }
                        } else {
                            this.device_1_online = false
                        }

                    }

                    if(data.id === this.device_2) {
                        if("online" in data) {
                            if(data.online) {
                                this.device_2_online = true
                            } else {
                                this.device_2_online = false
                            }
                        } else {
                            this.device_2_online = false
                        }

                    }


                    if(data.id === this.device_3) {
                        if("online" in data) {
                            if(data.online) {
                                this.device_3_online = true
                            } else {
                                this.device_3_online = false
                            }
                        } else {
                            this.device_3_online = false
                        }

                    }
                }
            });
        },

        fetchStatuses() {
            let vm = this
            let payload = new Array();

            payload.push(this.device_1);
            payload.push(this.device_2);
            payload.push(this.device_3);

            //window.console.log((payload))

            this.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('DEVICE_STATUS', {
                devices: payload
            })
            .then(response =>  {
                window.console.log(response.data)
                vm.setStatuses(response.data.payload)

            })
            .catch(error => {
                window.console.error("### ParkingBarriers@fetchStatuses ###")
                window.console.log(error)
            })
            .finally(() => {
                this.$store.commit('SET_PROGRESS', false)
            })
        },

        setStatuses(payload) {
            let vm = this
            payload.forEach(item => {
                if(vm.device_1 == item.device_uuid) {
                    vm.device_1_status = item.status
                    vm.device_1_update_timestamp = item.updated_at_formatted
                    vm.device_1_update_timestamp_dt = item.updated_at
                    vm.device_1_soc_temp = item.soc_temp
                }

                if(vm.device_2 == item.device_uuid) {
                    vm.device_2_status = item.status
                    vm.device_2_update_timestamp = item.updated_at_formatted
                    vm.device_2_update_timestamp_dt = item.updated_at
                    vm.device_2_soc_temp = item.soc_temp
                }

                if(vm.device_3 == item.device_uuid) {
                    vm.device_3_status = item.status
                    vm.device_3_update_timestamp = item.updated_at_formatted
                    vm.device_3_update_timestamp_dt = item.updated_at
                    vm.device_3_soc_temp = item.soc_temp
                }
            })

            window.console.log("Device 1 status: ", vm.device_1_status)
            window.console.log("Device 2 status: ", vm.device_2_status)
            window.console.log("Device 3 status: ", vm.device_3_status)
        }
        
    },

    created() {
        window.console.log(this.$route.name)
        this.$store.dispatch('ROUTE_NAME', this.$route.name)

        this.fetchStatuses();
        this.interval = setInterval(() => {
            this.fetchStatuses();
        }, process.env.VUE_APP_DEVICE_STATUS_REFRESH_INTERVAL || 8000)

        this.setupDeviceData()
        this.interval2 = setInterval(() => {
            this.setupDeviceData()
        }, 1000)


    },

    mounted() {
        
    },

    beforeDestroy() {
        clearInterval(this.interval)
        clearInterval(this.interval2)
        clearTimeout(this.timeout1)
        clearTimeout(this.timeout2)
    },

    destroyed() {
        
    }
}

</script>

<style scoped>
    .dvig {
        background: #4ba36b !important;
        color: #fff;
    }

    .spust {
        background: #e79e42 !important;
        color: #fff;
    }

    .stalni_dvig {
        background: #ce5959 !important;
        color: #fff;
    }

    .beacon_green{
        position:absolute;
        /*top:50%;
        left:50%;*/
        background-color:#0cd12d;;
        height:1em;
        width:1em;
        border-radius:50%;
        -webkit-animation: grow .4s 1 linear;
        animation: grow .4s 1 linear;
        -webkit-transform:translateX(-50%) translateY(-50%);
    }
    .beacon_green:before{
        position:absolute;
        content:"";
        height:1em;
        width:1em;
        left:0;
        top:0;
        background-color:transparent;
        border-radius:50%;
        box-shadow:0px 0px 2px 2px #0cd12d;
        -webkit-animation:active 2s infinite linear;
        animation:active 2s infinite linear;
    }


    .beacon_red{
        position:absolute;
        /*top:50%;
        left:50%;*/
        background-color:#DB2828;
        height:1em;
        width:1em;
        border-radius:50%;
        -webkit-animation: grow .4s 1 linear;
        animation: grow .4s 1 linear;
        -webkit-transform:translateX(-50%) translateY(-50%);
    }
    .beacon_red:before{
        position:absolute;
        content:"";
        height:1em;
        width:1em;
        
        background-color:transparent;
        border-radius:50%;
        box-shadow:0px 0px 2px 2px #DB2828;
        -webkit-animation:active 2s infinite linear;
        animation:active 2s infinite linear;
    }

    @-webkit-keyframes grow {
        0% {
            -webkit-transform: scale(.1);
        }
        
        100% {
            -webkit-transform: scale(1);
        }
        }

    @keyframes grow {
        0% {
            transform: scale(.1);
        }
        
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes active{
        0%{
            -webkit-transform:scale(.1);
            opacity:1;
        }
        70%{
            -webkit-transform:scale(2.5);
            opacity:0;
        }
        100%{
            opacity:0;
        }
    }

    @keyframes active{
        0%{
            transform:scale(.1);
            opacity:1;
        }
        70%{
            transform:scale(2.5);
            opacity:0;
        }
        100%{
            opacity:0;
        }
    }

</style>